<template>
  <div class="home-wrapper">
    <logo />

    <h1 class="text-2xl">{{ $t('static.serviceError') }}</h1>

    <p>{{ $t('static.errorExplain') }}</p>

    <button
      type="button"
      class="mt-5 focus:outline-none"
      @click="$router.go(-1)"
    >
      <icon :icon="mdiChevronLeft" :size="IconSize.SM" />
      {{ $t('static.goBack') }}
    </button>
  </div>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import Logo from '@/components/Logo/Logo';
import { mdiChevronLeft } from '@mdi/js';

export default {
  components: {
    Icon,
    Logo,
  },
  data() {
    return { mdiChevronLeft, IconSize };
  },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  @apply p-4 flex flex-col items-center justify-center h-screen text-white bg-cover;
  background-image: url('../../assets/background.png');
}
</style>
