<template>
  <div class="home-wrapper">
    <logo />

    <h1 class="text-2xl">{{ $t('static.notFound') }}</h1>

    <btn
      :theme="BtnTheme.SECONDARY"
      class="p-4 mt-4.5"
      @click="$router.push({ name: initialRouteName() })"
    >
      <icon :icon="mdiHome" :size="IconSize.SM" class="mr-1" />
      {{ $t('static.homePage') }}
    </btn>
    <button
      type="button"
      class="focus:outline-none mt-4.5 -ml-3"
      @click="$router.go(-1)"
    >
      <icon :icon="mdiChevronLeft" :size="IconSize.SM" />
      {{ $t('static.goBack') }}
    </button>
  </div>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import Logo from '@/components/Logo/Logo';
import { mdiChevronLeft } from '@mdi/js';
import { Btn, BtnTheme } from '@/components/Btn';
import { mdiHome } from '@mdi/js';
import { useAuthUser } from '@/composables';

export default {
  components: {
    Logo,
    Icon,
    Btn,
  },
  setup() {
    const { initialRouteName } = useAuthUser();

    return { mdiChevronLeft, BtnTheme, IconSize, mdiHome, initialRouteName };
  },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  @apply p-4 flex flex-col items-center justify-center h-screen text-white bg-cover;
  background-image: url('../../assets/background.png');
}
</style>
