<template>
  <router-link :tag="tag" :to="{ name: 'Login' }">
    <objekto-logo class="fill-current mb-8 h-20" />
  </router-link>
</template>

<script>
import ObjektoLogo from '@/assets/Objekto_Logo.svg';

export default {
  components: {
    ObjektoLogo,
  },
  computed: {
    tag() {
      return this.$route.name === 'Login' ? 'div' : 'a';
    },
  },
};
</script>
